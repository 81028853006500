<template>
  <div class="container flex-1 py-8 bg-white lg:px-10 max-w-7xl lg:pt-10 lg:pb-20">
    <div class="prose-sm prose sm:prose prose-indigo sm:max-w-none">
      <h1>Terms &amp; Conditions</h1>
      <p><strong>INTRODUCTION</strong></p>
      <p>
        This Agreement contains the complete terms and conditions that apply to your participation in our site. The
        Agreement describes and encompasses the entire agreement between us and you, and supersedes all prior or
        contemporaneous agreements, representations, warranties and understandings with respect to the Site, the content
        and computer programs provided by or through the Site, and the subject matter of this Agreement. Please read
        these terms of use carefully before using the services. By accessing this site or using any part of the site or
        any content or services hereof, you agree to become bound by these terms and conditions. If you do not agree to
        all the terms and conditions, then you may not access the site or use the content or any services in the site.
        Amendments to this agreement can be made and effected by us from time to time without specific notice to your
        end. Agreement posted on the Site reflects the latest agreement and you should carefully review the same before
        you use our site.
      </p>
      <p><strong>Use of the site &amp; PROHIBITIONS</strong></p>
      <p>
        This site allows you to join the blog, express your thoughts, make comments and discussions. You understand and
        agree that you will use this site including its tools and services with full sense of responsibility and in a
        manner that is consistent with these Terms and in such a way as to ensure compliance with all applicable laws
        and regulations. You agree that you will use the Service in compliance with all applicable local, state,
        national, and international laws, rules and regulations, including any laws regarding the transmission of
        technical data exported from your country of residence and all United States export control laws.
      </p>
      <p>
        However, you are prohibited to do the following acts, to wit: (a) use our sites, including its services and or
        tools if you are not able to form legally binding contracts, are under the age of 18, or are temporarily or
        indefinitely suspended from using our sites, services, or tools (b) posting of an blogs, items, messages, and or
        contents that are inappropriate and fails to observe decency; (c) collecting information about usersí personal
        information; (d) post false, inaccurate, misleading, defamatory, or libelous content; (e) take any action that
        may damage the rating system.
      </p>
      <p>No Resale of the Service.</p>
      <p>
        You agree and understand that you will not reproduce, duplicate, copy, sell, trade, resell or exploit for any
        commercial purposes any portion of the site including its tools and services.
      </p>
      <p><strong>YOUR REGISTRATION OBLIGATIONS</strong></p>
      <p>
        As a condition in using the site including its tools and services, you are required to register with the site
        and select a password and username. You must complete the full registration process and shall provide the site
        with accurate, complete, and updated registration information. Failure to do so shall constitute a breach of the
        Terms of Use, which may result in immediate termination of your account. You must qualify that you are 18 years
        or older and must be responsible for keeping your password secure and be responsible for all activities and
        contents that are uploaded under your account. You must not transmit any worms or viruses or any code of a
        destructive nature. Any information provided by you or gathered by the site or third parties during any visit to
        the site shall be subject to the terms of&nbsp; wealth-info.com Privacy Policy.
      </p>
      <p><strong>WARRANTY DISCLAIMER AND EXCLUSIONS / LIMITATIONS OF LIABILITY</strong></p>
      <p>
        You represent and warrant that (a) all of the information provided by you to our website to participate in the
        Service is correct and current; and (b) you have all necessary right, power and authority to enter into this
        Agreement and to perform the acts required of you hereunder.
      </p>
      <p>
        You hereby accepts and agree that it is beyond our control, and no duty to take any action regarding: which
        users gain access to the Site or use the Services; what affects the Content may have on you; how you may
        interpret or use the Content; or what actions you may take as a result of having been exposed to the Content.
        You release us from all liability for you having acquired or not acquired Content through the Site or the
        Services.
      </p>
      <p>
        It should be noted that the Site or Services may contain, or direct you to sites containing, information that
        some people may find offensive or inappropriate. We make no representations concerning any content contained in
        or accessed through the Site or Services, and we will not be responsible or liable for the accuracy, copyright
        compliance, legality or decency of material contained in or accessed through the Site or the Services.
      </p>
      <p>
        THE SERVICE, CONTENT, AND SITE ARE PROVIDED ON AN “AS IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS
        OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE OR NON-INFRINGEMENT. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
        ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
      </p>
      <p>
        In addition, we make no representation that the operation of our site will be uninterrupted or error-free, and
        we will not be liable for the consequences of any interruptions or errors. We may change, restrict access to,
        suspend or discontinue the site or any part of it at anytime. The information, content and services on the site
        are provided on an ìas isî basis. When you use the site and or participate herein, you understand and agree that
        you participate at your own risk.
      </p>
      <p><strong>INTELLECTUAL PROPERTY rights</strong></p>
      <p>
        The Web allows people throughout the world to share valuable information, ideas and creative works. To ensure
        continued open access to such materials, we all need to protect the rights of those who share their creations
        with us. Although we make the Site freely accessible, we don’t intend to give up our rights, or anyone else’s
        rights, to the materials appearing on them. The materials available on the site shall remain the property of
        wealth-info.com and/or its licensors, and are protected by copyright, trademark and other intellectual property
        laws. You acquire no proprietary interest in any such rights. Furthermore, you may not remove or obscure the
        copyright notice or any other notices contained in the site or anything retrieved or downloaded from them.
      </p>
      <p>
        You hereby acknowledge that all rights, titles and interests, including but not limited to rights covered by the
        Intellectual Property Rights, in and to the site, and that You will not acquire any right, title, or interest in
        or to the site except as expressly set forth in this Agreement. You will not modify, adapt, translate, prepare
        derivative works from, decompile, reverse engineer, disassemble or otherwise attempt to derive source code from
        any of our services, software, or documentation, or create or attempt to create a substitute or similar service
        or product through use of or access to the Program or proprietary information related thereto.
      </p>
      <p><strong>Confidentiality</strong></p>
      <p>
        You agree not to disclose information you obtain from us and or from our clients, advertisers, suppliers and
        forum members. All information submitted to by an end-user customer pursuant to a Program is proprietary
        information of wealth-info.com Such customer information is confidential and may not be disclosed. Publisher
        agrees not to reproduce, disseminate, sell, distribute or commercially exploit any such proprietary information
        in any manner.
      </p>
      <p><strong>NON-ASSIGNMENT OF RIGHTS</strong></p>
      <p>
        Your rights of whatever nature cannot be assigned nor transferred to anybody, and any such attempt may result in
        termination of this Agreement, without liability to us. However, we may assign this Agreement to any person at
        any time without notice.
      </p>
      <p><strong>Waiver and Severability of Terms.</strong></p>
      <p>
        Failure of the wealth-info.com to insist upon strict performance of any of the terms, conditions and covenants
        hereof shall not be deemed a relinquishment or waiver of any rights or remedy that the we may have, nor shall it
        be construed as a waiver of any subsequent breach of the terms, conditions or covenants hereof, which terms,
        conditions, and covenants shall continue to be in full force and effect.
      </p>
      <p>
        No waiver by either party of any breach of any provision hereof shall be deemed a waiver of any subsequent or
        prior breach of the same or any other provision.
      </p>
      <p>
        In the event that any provision of these Terms and Conditions is found invalid or unenforceable pursuant to any
        judicial decree or decision, such provision shall be deemed to apply only to the maximum extent permitted by
        law, and the remainder of these Terms and Conditions shall remain valid and enforceable according to its terms.
      </p>
      <p><strong>Entire Agreement</strong></p>
      <p>
        This Agreement shall be governed by and construed in accordance with the substantive laws of the United Kingdom,
        without any reference to conflict-of-laws principles. The Agreement describes and encompasses the entire
        agreement between us and you, and supersedes all prior or contemporaneous agreements, representations,
        warranties and understandings with respect to the Site, the contents and materials provided by or through the
        Site, and the subject matter of this Agreement.
      </p>
      <p><strong>Choice of Law; Jurisdiction; Forum</strong></p>
      <p>
        Any dispute, controversy or difference which may arise between the parties out of, in relation to or in
        connection with this Agreement is hereby irrevocably submitted to the exclusive jurisdiction of the courts of
        United Kingdom, to the exclusion of any other courts without giving effect to its conflict of laws provisions or
        your actual state or country of residence.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>